import React from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <div className="logo">
              <h1><b>Aangan-e-aroos</b></h1>
            </div>
            <p>Unleash your beauty!</p>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>About Us</h2>
            <ul>
              {/* <li>Careers</li>
                <li>Our Stores</li>
                <li>Our Cares</li> */}
              <li>Terms & Conditions</li>
              <li>Privacy Policy</li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Customer Care</h2>
            <ul>
              <li>Help Center </li>
              <li>How to Buy </li>
              <li>Track Your Order </li>
              <li>Corporate & Bulk Purchasing </li>
              <li>Returns & Refunds </li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Contact Us</h2>
            <ul >
              {/* <li>70 Washington Square South, New York, NY 10012, United States </li> */}
              <li>
                Email:{" "}
                <a style={{ color: "white" }} href="mailto:aanganearoos@gmail.com">
                  aanganearoos@gmail.com
                </a>
              </li>
              <li>
                Phone: <a style={{ color: "white" }} href="tel:+923214167996">+92 321 4167996</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
